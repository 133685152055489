export default function Slider({ children, current }) {
  return (
    <div className="wrapper">
      <div className="slides">
        {children.map((Component, index) => (
          <div
            key={`form-${index}`}
            className={`slide ${current === index && "active"}`}
          >
            {Component}
          </div>
        ))}
      </div>
    </div>
  );
}
