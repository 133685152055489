import React, { Fragment, useContext } from "react";
import { Button, Col, Descriptions, Divider, Row, Typography } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import { CATEGORIES } from "constants/data";
import { AppContext } from "../App";

const { Title, Paragraph } = Typography;

const Summary = ({ merchant, onPrev, onSubmit, loading }) => {
  const { t } = useTranslation();
  const cities = useContext(AppContext);

  return (
    <>
      <div style={{ textAlign: "center", marginBottom: 24 }}>
        <Paragraph style={{ marginBottom: 24 }}>
          {t("Step")} {t("8")} / {t("8")}
        </Paragraph>

        <Title>{t("Submit Registration")}</Title>
        <Paragraph style={{ width: "100%", maxWidth: 512, margin: "0 auto" }}>
          {t("Take a moment to review the information you entered")}
        </Paragraph>
      </div>

      <Row
        gutter={[
          { xs: 0, sm: 24 },
          { xs: 24, sm: 0 },
        ]}
      >
        <Col xs={24} sm={12}>
          <Descriptions title={t("Partner Type")} column={1}>
            <Descriptions.Item label={t("Type")}>
              <span className="text-capitalize">{merchant.type}</span>
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col xs={24} sm={12}>
          <Descriptions title={t("Business Name")} column={1}>
            <Descriptions.Item label={t("DBA / Commercial Name")}>
              {merchant?.commercialName}
            </Descriptions.Item>
            <Descriptions.Item label={t("Legal name")}>
              {merchant?.legalName}
            </Descriptions.Item>
            <Descriptions.Item label={t("Related Organization Name")}>
              {merchant?.organizationName ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label={t("Related Organizatin Address")}>
              {merchant?.organizationAddress ?? "-"}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>

      <Row
        gutter={[
          { xs: 0, sm: 24 },
          { xs: 24, sm: 0 },
        ]}
      >
        <Col xs={24} sm={12}>
          <Descriptions title={t("Business Locations")} column={1}>
            {merchant?.businessLocations &&
              merchant.businessLocations.map((business, index) => (
                <Fragment key={index}>
                  <Descriptions.Item label={t("Business Name")}>
                    {business?.name}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("Address")}>
                    {business?.address}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("City")}>
                    {
                      cities.find((city) => get(city, "_id") === business?.city)
                        ?.name
                    }
                  </Descriptions.Item>
                  <Descriptions.Item label={t("Latitude")}>
                    {business?.lat}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("Longtitude")}>
                    {business?.lng}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("Category")}>
                    {business?.category &&
                      CATEGORIES.find((c) => c.value === business.category)
                        .label}
                  </Descriptions.Item>
                </Fragment>
              ))}
          </Descriptions>
        </Col>
        <Col xs={24} sm={12}>
          <Descriptions title={t("Business Images")} column={1}>
            <Descriptions.Item label={t("Logo")}>
              {merchant?.logo && merchant.logo.length && (
                <img
                  src={merchant?.logo[0]?.thumbUrl}
                  alt="Logo"
                  style={{ width: 128 }}
                />
              )}
            </Descriptions.Item>
            <Descriptions.Item label={t("Exterior Photo")}>
              {merchant?.exteriorPhoto && merchant.exteriorPhoto.length && (
                <img
                  src={merchant?.exteriorPhoto[0]?.thumbUrl}
                  alt="Exterior"
                  style={{ width: 128 }}
                />
              )}
            </Descriptions.Item>
            <Descriptions.Item label={t("Interior Photo")}>
              {merchant?.interiorPhoto && merchant.interiorPhoto.length && (
                <img
                  src={merchant?.interiorPhoto[0]?.thumbUrl}
                  alt="Interior"
                  style={{ width: 128 }}
                />
              )}
            </Descriptions.Item>
            <Descriptions.Item label={t("Additional document")}>
              {merchant?.additionalDocument &&
                merchant.additionalDocument.length && (
                  <img
                    src={merchant?.additionalDocument[0]?.thumbUrl}
                    alt="additional document"
                    style={{ width: 128 }}
                  />
                )}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>

      <Row
        gutter={[
          { xs: 0, sm: 24 },
          { xs: 24, sm: 0 },
        ]}
      >
        <Col xs={24} sm={12}>
          <Descriptions title={t("Business Contacts")} column={1}>
            {merchant?.businessContacts &&
              merchant.businessContacts.map((contact, index) => (
                <Fragment key={index}>
                  <Descriptions.Item label={t("Full Name")}>
                    {contact?.name}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("Email")}>
                    {contact?.email}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("Phone Number")}>
                    {contact?.phoneNumber}
                  </Descriptions.Item>
                </Fragment>
              ))}
          </Descriptions>
        </Col>
        <Col xs={24} sm={12}>
          <Descriptions title={t("Fees & Settlement")} column={1}>
            <Descriptions.Item label={t("Bank Name")}>
              {merchant?.bankName ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label={t("Account Name")}>
              {merchant?.accountName ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label={t("Account Number")}>
              {merchant?.accountNumber ?? "-"}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>

      <Paragraph
        style={{
          width: "100%",
          maxWidth: 512,
          margin: "0 auto",
          textAlign: "center",
          marginTop: 24,
        }}
      >
        {t(
          "If all information is correct, click the Submit button below to complete your registration and finish the process"
        )}
      </Paragraph>

      <Divider />

      <Row style={{ width: "100%" }} justify="space-between" align="middle">
        <Col>
          <Button
            htmlType="button"
            disabled={loading}
            icon={<ArrowLeftOutlined />}
            onClick={onPrev}
          >
            {t("Back")}
          </Button>
        </Col>
        <Col>
          <Button
            loading={loading}
            type="primary"
            htmlType="button"
            onClick={() => {
              onSubmit();
            }}
          >
            {t("Submit")}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Summary;
