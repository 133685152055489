import React, { useState, useEffect } from "react";
import { Alert, Form } from "antd";
import Layout from "components/Layout";
import Welcome from "components/Welcome";
import BusinessType from "components/BusinessType";
import BusinessInformation from "components/BusinessInformation";
import BusinessLocation from "components/BusinessLocation";
import BusinessImage from "components/BusinessImage";
import BusinessContact from "components/BusinessContact";
import BusinessSettlement from "components/BusinessSettlement";
import BusinessAgreement from "components/BusinessAgreement";
import Summary from "components/Summary";
import Congrats from "components/Congrats";
import Slider from "components/Slider";
import { config } from "./config";

import "./App.less";

export const AppContext = React.createContext(null);

const App = () => {
  const [form] = Form.useForm();
  const slideLength = 9;
  const endpoint = process.env.REACT_APP_SUBMIT_API_URL;
  const [showForm, setShowForm] = useState(false);
  const [current, setCurrent] = useState(0);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [cities, setCities] = useState([]);
  const [merchant, setMerchant] = useState({});

  useEffect(() => {
    const onFetchCities = async () => {
      const url = `${config.baseURL}/city/list`;
      const res = await fetch(url);
      const results = await res.json();

      if (!results?.data) return;
      setCities(results.data);
    };

    onFetchCities();
  }, []);

  const handleNext = () => {
    setCurrent(current === slideLength - 1 ? 0 : current + 1);
  };

  const handlePrev = () => {
    setCurrent(current === 0 ? slideLength - 1 : current - 1);
  };

  const onSubmit = async (values) => {
    setErrorMessage("");
    setLoading(true);
    const res = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    });

    const results = await res.json();

    // console.log(results);

    if (results?.message) {
      setErrorMessage(results?.message);
    } else {
      form.resetFields();
      setCurrent((prev) => prev + 1);
    }
    setLoading(false);
  };

  const onFinish = async () => {
    const fieldsValue = merchant;
    // merge businessLocations with settlement
    const logo =
      fieldsValue?.logo && fieldsValue.logo.length
        ? {
            name: fieldsValue.logo[0].name,
            type: fieldsValue.logo[0].type,
            size: Math.round(fieldsValue.logo[0].size / 1000) + " kB",
            base64: fieldsValue.logo[0].thumbUrl,
          }
        : fieldsValue.logo;
    const interiorPhoto =
      fieldsValue?.interiorPhoto && fieldsValue.interiorPhoto.length
        ? {
            name: fieldsValue.interiorPhoto[0].name,
            type: fieldsValue.interiorPhoto[0].type,
            size: Math.round(fieldsValue.interiorPhoto[0].size / 1000) + " kB",
            base64: fieldsValue.interiorPhoto[0].thumbUrl,
          }
        : fieldsValue.interiorPhoto;
    const exteriorPhoto =
      fieldsValue?.exteriorPhoto && fieldsValue.exteriorPhoto.length
        ? {
            name: fieldsValue.exteriorPhoto[0].name,
            type: fieldsValue.exteriorPhoto[0].type,
            size: Math.round(fieldsValue.exteriorPhoto[0].size / 1000) + " kB",
            base64: fieldsValue.exteriorPhoto[0].thumbUrl,
          }
        : fieldsValue.logo;
    const additionalDocument =
      fieldsValue?.additionalDocument && fieldsValue.additionalDocument.length
        ? {
            name: fieldsValue.additionalDocument[0].name,
            type: fieldsValue.additionalDocument[0].type,
            size:
              Math.round(fieldsValue.additionalDocument[0].size / 1000) + " kB",
            base64: fieldsValue.additionalDocument[0].thumbUrl,
          }
        : fieldsValue.additionalDocument;

    const values = {
      ...fieldsValue,
      logo,
      interiorPhoto,
      exteriorPhoto,
      additionalDocument,
    };

    onSubmit(values);
  };

  return (
    <Layout>
      {showForm ? (
        <Form.Provider
          onFormFinish={(name, { values, forms }) => {
            if (
              [
                "businessType",
                "businessInformation",
                "businessLocations",
                "businessImages",
                "businessContacts",
                "businessSettlement",
                "businessAgreement",
              ].includes(name)
            ) {
              handleNext();
            }

            if (name === "businessLocations") {
              const { businessSettlement } = forms;
              businessSettlement.setFieldsValue({
                ...values,
              });
            }

            if (name === "businessAgreement") {
              const {
                businessType,
                businessInformation,
                businessLocations,
                businessImages,
                businessContacts,
                businessSettlement,
              } = forms;
              const businessTypeValue = businessType.getFieldsValue();
              const businessInformationValue = businessInformation.getFieldsValue();
              const businessLocationsValue = businessLocations.getFieldsValue();
              const businessImagesValue = businessImages.getFieldsValue();
              const businessContactsValue = businessContacts.getFieldsValue();
              const businessSettlmentValue = businessSettlement.getFieldsValue();

              setMerchant({
                ...businessTypeValue,
                ...businessInformationValue,
                ...businessLocationsValue,
                ...businessImagesValue,
                ...businessContactsValue,
                ...businessSettlmentValue,
                ...values,
              });
            }
          }}
        >
          <AppContext.Provider value={cities}>
            <Slider current={current}>
              <div>
                <BusinessType />
              </div>
              <div>
                <BusinessInformation onPrev={handlePrev} />
              </div>
              <div>
                <BusinessLocation onPrev={handlePrev} />
              </div>
              <div>
                <BusinessImage onPrev={handlePrev} />
              </div>
              <div>
                <BusinessContact onPrev={handlePrev} />
              </div>
              <div>
                <BusinessSettlement onPrev={handlePrev} />
              </div>
              <div>
                <BusinessAgreement onPrev={handlePrev} />
              </div>
              <div>
                <Summary
                  merchant={merchant}
                  onPrev={handlePrev}
                  onSubmit={onFinish}
                  loading={loading}
                />
              </div>
              <div>
                <Congrats />
              </div>
            </Slider>
          </AppContext.Provider>

          {errorMessage && (
            <Alert
              message="Error"
              description={errorMessage}
              type="error"
              closable
            />
          )}
        </Form.Provider>
      ) : (
        <Welcome onShowForm={() => setShowForm(true)} />
      )}
    </Layout>
  );
};

export default App;
