import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Typography,
} from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { BANKS } from "constants/data";

const { Option } = Select;
const { Title, Paragraph } = Typography;

const BusinessSettlement = ({ onPrev }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  return (
    <div>
      <div style={{ textAlign: "center", marginBottom: 24 }}>
        <Paragraph style={{ marginBottom: 24 }}>
          {t("Step")} {t("6")} / {t("8")}
        </Paragraph>
        <Title>{t("Agree on Fees and Settlement")}</Title>
      </div>

      <Form
        form={form}
        layout="vertical"
        name="businessSettlement"
        autoComplete="off"
      >
        <div>
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.businessLocations !== curValues.businessLocations
            }
          >
            {({ getFieldsValue }) => {
              return (
                <Form.List name="businessLocations">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(
                        ({ key, name, fieldKey, ...restField }, index) => (
                          <Row key={key} gutter={16}>
                            <Col span={8}>
                              <Form.Item
                                label={index > 0 ? "" : "Business Name"}
                                name={[name, "name"]}
                                fieldKey={[fieldKey, "name"]}
                              >
                                <Input disabled />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                label={index > 0 ? "" : "Discount Rate"}
                                name={[name, "rate"]}
                                fieldKey={[fieldKey, "rate"]}
                              >
                                <InputNumber
                                  style={{ width: "100%" }}
                                  step="5"
                                  min={0}
                                  max={100}
                                  formatter={(value) => `${value}%`}
                                  parser={(value) =>
                                    `${value}`.replace("%", "")
                                  }
                                />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                label={index > 0 ? "" : "Settlement Period"}
                                name={[name, "period"]}
                                fieldKey={[fieldKey, "period"]}
                              >
                                <InputNumber
                                  style={{ width: "100%" }}
                                  min={0}
                                  max={90}
                                  formatter={(value) => `${value} working days`}
                                  parser={(value) =>
                                    `${value}`.replace("working days", "")
                                  }
                                />
                              </Form.Item>
                            </Col>
                            <Col span={4}>
                              <Form.Item
                                label={index > 0 ? "" : "Refundable"}
                                name={[name, "refundable"]}
                                fieldKey={[fieldKey, "refundable"]}
                                valuePropName="checked"
                              >
                                <Checkbox />
                              </Form.Item>
                            </Col>
                          </Row>
                        )
                      )}
                    </>
                  )}
                </Form.List>
              );
            }}
          </Form.Item>
        </div>

        <div>
          <Paragraph strong>{t("Settlement Methods (Bank Account)")}</Paragraph>
          <Form.Item name="bankName">
            <Select placeholder="Select a bank">
              {BANKS.map(({ value, label }) => (
                <Option key={value} value={value}>
                  {label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="accountName">
            <Input placeholder="Account Name" />
          </Form.Item>
          <Form.Item name="accountNumber">
            <Input placeholder="Account Number" />
          </Form.Item>
        </div>

        <div>
          <Form.Item
            name="isSettlement"
            valuePropName="checked"
            rules={[
              { required: true, message: "Accept settlement is reqiured!" },
            ]}
          >
            <Checkbox>
              {t(
                "I hereby certify that I have acknowledged and accepted the discount rate and settlement period"
              )}
            </Checkbox>
          </Form.Item>
        </div>

        <Divider />

        <Form.Item>
          <Row gutter={16} justify="space-between" align="middle">
            <Col>
              <Button
                htmlType="button"
                icon={<ArrowLeftOutlined />}
                onClick={onPrev}
              >
                {t("Back")}
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                htmlType="button"
                onClick={() => {
                  form.submit();
                }}
              >
                {t("Next")} <ArrowRightOutlined />
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </div>
  );
};

export default BusinessSettlement;
