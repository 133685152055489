import React from "react";
import { Button, Col, Divider, Form, Input, Row, Typography } from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const { Title, Paragraph } = Typography;

const BusinessInformation = ({ onPrev }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  return (
    <>
      <div style={{ textAlign: "center", marginBottom: 24 }}>
        <Paragraph style={{ marginBottom: 24 }}>
          {t("Step")} {t("2")} / {t("8")}
        </Paragraph>
        <Title>{t("Enter Business Name")}</Title>
      </div>

      <Form
        form={form}
        name="businessInformation"
        layout="vertical"
        autoComplete="off"
      >
        <div>
          <Paragraph strong className="mark-required">
            {t("Fields marked with an asterisk ( * ) are required")}
          </Paragraph>

          <Paragraph strong>
            {t("Commercial business name - DBA")}{" "}
            <span className="error">*</span>
          </Paragraph>
          <Paragraph>{t("DBA (Doing Business As)")}</Paragraph>
          <Form.Item
            name="commercialName"
            rules={[{ required: true, message: "Missing commercial name!" }]}
          >
            <Input placeholder="Enter commercial name" />
          </Form.Item>
        </div>

        <div>
          <Paragraph strong>
            {t("Legal name")} <span className="error">*</span>
          </Paragraph>
          <Paragraph>{t("Full legal name")}</Paragraph>
          <Form.Item
            name="legalName"
            rules={[{ required: true, message: "Missing legal name!" }]}
          >
            <Input placeholder="Enter legal name" />
          </Form.Item>
        </div>

        <div>
          <Paragraph strong>{t("Parent Company")}</Paragraph>
          <Paragraph>{t("Include if the business")}</Paragraph>
          <Form.Item
            name="organizationName"
            label={t("Related Organization Name")}
          >
            <Input placeholder="Enter parent company or organization name" />
          </Form.Item>
          <Form.Item name="organizationAddress" label={t("Address")}>
            <Input placeholder="Enter parent company or organization address " />
          </Form.Item>
        </div>

        <Divider />

        <Form.Item>
          <Row gutter={16} justify="space-between" align="middle">
            <Col>
              <Button
                htmlType="button"
                icon={<ArrowLeftOutlined />}
                onClick={onPrev}
              >
                {t("Back")}
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                htmlType="button"
                onClick={() => {
                  form.submit();
                }}
              >
                {t("Next")} <ArrowRightOutlined />
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </>
  );
};

export default BusinessInformation;
