import React, { useContext } from "react";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Typography,
} from "antd";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { CATEGORIES } from "constants/data";
import { AppContext } from "../App";

const { Option } = Select;
const { Title, Paragraph } = Typography;

const BusinessLocation = ({ onPrev }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const cities = useContext(AppContext);

  const handleChangeCategory = (index) => (categoryId) => {
    const locations = form.getFieldValue("businessLocations");
    const fee = CATEGORIES.find((category) => category.value === categoryId);
    locations[index] = {
      ...locations[index],
      rate: fee.rate,
      period: fee.period,
      refundable: false,
    };
  };

  return (
    <>
      <div style={{ textAlign: "center", marginBottom: 24 }}>
        <Paragraph style={{ marginBottom: 24 }}>
          {t("Step")} {t("3")} / {t("8")}
        </Paragraph>
        <Title>{t("Enter Business Location")}</Title>
      </div>

      <div style={{ marginBottom: 24 }}>
        <Paragraph strong className="mark-required">
          {t("Fields marked with an asterisk ( * ) are required")}
        </Paragraph>
        <Form form={form} name="businessLocations" autoComplete="off">
          <Form.List name="businessLocations" initialValue={[{}]}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                  <div key={key}>
                    {index !== 1 ? null : (
                      <>
                        <Paragraph strong>
                          {t("Only fill in additional business locations if")}
                        </Paragraph>
                        <ul>
                          <li>
                            {t("You operate more than one location or branch")}
                          </li>
                          <li>
                            {t(
                              "One location has more than one category, in which case use the same location again with the other category"
                            )}
                          </li>
                        </ul>
                      </>
                    )}
                    <Row justify="space-between">
                      <Col xs={22} sm={23}>
                        <Paragraph strong>
                          {index !== 0
                            ? `${t("Business location")} ${index + 1} `
                            : `${t("Main Business Location")} `}
                          <span className="error">*</span>
                        </Paragraph>

                        <Paragraph>
                          {t(
                            "Business name, Address and Latitude/Longitude Coordinates"
                          )}
                        </Paragraph>
                        <Form.Item
                          name={[name, "name"]}
                          fieldKey={[fieldKey, "name"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing business name!",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Business Name"
                            autoComplete="off"
                          />
                        </Form.Item>
                        <Form.Item
                          name={[name, "address"]}
                          fieldKey={[fieldKey, "address"]}
                          rules={[
                            { required: true, message: "Missing address!" },
                          ]}
                        >
                          <Input placeholder="Address" autoComplete="off" />
                        </Form.Item>
                        <Form.Item
                          name={[name, "city"]}
                          fieldKey={[fieldKey, "city"]}
                          rules={[{ required: true, message: "Missing city!" }]}
                        >
                          <Select placeholder="Select a city / province">
                            {cities.map(({ _id, name }) => (
                              <Option key={_id} value={_id}>
                                {name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name={[name, "lat"]}
                          fieldKey={[fieldKey, "lat"]}
                          rules={[
                            { required: true, message: "Missing latitude!" },
                          ]}
                        >
                          <InputNumber
                            placeholder="Latitude"
                            className="w-full"
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "lng"]}
                          fieldKey={[fieldKey, "lng"]}
                          rules={[
                            { required: true, message: "Missing longitude!" },
                          ]}
                        >
                          <InputNumber
                            placeholder="Longtitude"
                            className="w-full"
                          />
                        </Form.Item>

                        <Paragraph strong>
                          {index !== 0
                            ? `${t("Business Location Category")} ${index + 1} `
                            : `${t("Main Business Category")}`}
                          <span className="error"> *</span>
                        </Paragraph>

                        <Paragraph>
                          {index !== 0
                            ? t(
                                "Select from the drop-down the category that best fits the business"
                              )
                            : t(
                                "Select from the drop-down the category that best fits the main business"
                              )}
                        </Paragraph>
                        <Form.Item
                          {...restField}
                          name={[name, "category"]}
                          fieldKey={[fieldKey, "category"]}
                          rules={[
                            { required: true, message: "Missing category" },
                          ]}
                        >
                          <Select
                            placeholder="Select a category"
                            onChange={handleChangeCategory(index)}
                          >
                            {CATEGORIES.map(({ value, label }) => (
                              <Option key={value} value={value}>
                                {label}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Col>
                    </Row>
                    <Divider />
                  </div>
                ))}
                <Row>
                  <Col span={23}>
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        {t("Add Business")}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
          </Form.List>

          <Divider />

          <Form.Item>
            <Row gutter={16} justify="space-between" align="middle">
              <Col>
                <Button
                  htmlType="button"
                  icon={<ArrowLeftOutlined />}
                  onClick={onPrev}
                >
                  {t("Back")}
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  htmlType="button"
                  onClick={() => {
                    form.submit();
                  }}
                >
                  {t("Next")} <ArrowRightOutlined />
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default BusinessLocation;
