import React from "react";
import { Button, Col, Divider, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import logo from "assets/logo.svg";

const { Title } = Typography;

const Welcome = ({ onShowForm }) => {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <div>
        <div style={{ textAlign: "center", marginBottom: 24 }}>
          <Title level={3}>{t("Welcome to the")}</Title>
          <Title>{t("Partner Registration")}</Title>
          <img src={logo} alt="Wonderpass" className="wonderpass-logo" />
        </div>
        <Row
          gutter={[
            { xs: 0, sm: 48 },
            { xs: 24, sm: 0 },
          ]}
        >
          <Col xs={24} sm={12}>
            <p>{t("Register now")}</p>
            <Title level={5}>{t("What is Wonderpass?")}</Title>
            <p>{t("Visit our site")}</p>
            {i18n.language === "kh" ? (
              <ul>
                <li>
                  Wonderpass ជាកម្មវិធី ម៉ាឃីតផ្លេសដែលបង្កើតឱកាស
                  និងទីផ្សារសំរាប់ដៃគូរបស់យើងក្នុងដំណាក់កាលក្រោយ
                  វិបត្តិកូវីដ១៩នេះ។
                </li>
                <li>
                  Wonderpass គាំទ្រដោយបច្ចេកវិទ្យារបស់ក្រុមហ៊ុន Wonderpass
                  Technology Co., Ltd. ដែលនឹងនាំមកជូននូវបទពិសោធន៍នៃ
                  ទេសចរណ៍ជំនាន់ ៤.០
                </li>
                <li>
                  ស្វែងយល់បន្ថែមសូមចូលទៅកាន់{" "}
                  <a href="https://wonderpass.asia/">wonderpass.asia</a>
                </li>
              </ul>
            ) : (
              <div css="text-align: center;">
                <a href="https://wonderpass.asia/">wonderpass.asia</a>
              </div>
            )}
          </Col>
          <Col xs={24} sm={12}>
            <Title level={5}>{t("Registration details")}</Title>
            <ul>
              <li>{t("8 steps 10 minutes")}</li>
              <li>
                {t("You will need")}
                <ul>
                  <li>{t("The business’ Owner or Manager")}</li>
                  <li>{t("Logo images and photos of the store or office")}</li>
                </ul>
              </li>
            </ul>
          </Col>
        </Row>
      </div>
      <Divider />
      <div style={{ textAlign: "right" }}>
        <Button type="primary" htmlType="button" onClick={onShowForm}>
          {t("Start Registration")}
        </Button>
      </div>
    </div>
  );
};
export default Welcome;
