import React from "react";
import { Link } from "react-router-dom";
import { Layout as AntdLayout, Button, Typography, Space } from "antd";
import { useTranslation } from "react-i18next";
import logo from "assets/logo.svg";

const { Header, Content, Footer } = AntdLayout;

const Layout = ({ children }) => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (code) => {
    i18n.changeLanguage(code);
  };

  return (
    <AntdLayout style={{ minHeight: "100vh", position: "relative" }}>
      <Header className="site-layout-header-background" style={{ padding: 0 }}>
        <div className="site-layout-header">
          <div className="site-layout-sub-header">
            <div className="logo">
              <Link to="/">
                <img src={logo} alt="wonderpass" style={{ width: 128 }} />
              </Link>
            </div>
            <div>
              <Button type="link" onClick={() => changeLanguage("kh")}>
                {t("km")}
              </Button>
              <Button type="link" onClick={() => changeLanguage("en")}>
                {t("en")}
              </Button>
            </div>
          </div>
        </div>
      </Header>
      <Content className="site-layout-content">
        <div
          className="site-layout-background"
          style={{ padding: 24, minHeight: 360 }}
        >
          {children}
        </div>
      </Content>
      <Footer style={{ textAlign: "center" }}>
        <Space direction="vertical">
          <Link to="terms-and-conditions">Terms and Conditions</Link>
          <Typography.Paragraph>
            © {new Date().getFullYear()} Wonderpass Technology Co., Ltd. All rights reserved.
          </Typography.Paragraph>
        </Space>
      </Footer>
    </AntdLayout>
  );
};

export default Layout;
