import React from "react";
import {
  Button,
  Card,
  Col,
  Checkbox,
  Divider,
  Form,
  Row,
  Typography,
} from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import Terms from "contents/terms.md";

const { Title, Paragraph } = Typography;

const BusinessAgreement = ({ onPrev }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [terms, setTerms] = React.useState();

  React.useEffect(() => {
    const handleFetchTerms = () => {
      fetch(Terms)
        .then((res) => res.text())
        .then((text) => setTerms(text));
    };

    handleFetchTerms();
  }, []);

  return (
    <div>
      <div style={{ textAlign: "center", marginBottom: 24 }}>
        <Paragraph style={{ marginBottom: 24 }}>
          {t("Step")} {t("7")} / {t("8")}
        </Paragraph>
        <Title>{t("Agreement & Refund Policy")}</Title>
      </div>

      <div style={{ marginBottom: 24 }}>
        <Card title="Terms & Conditions" bordered={false}>
          <Paragraph className="scrollable">
            <ReactMarkdown>{terms}</ReactMarkdown>
          </Paragraph>
        </Card>
      </div>

      <Form form={form} name="businessAgreement" autoComplete="off">
        <Form.Item
          name="acceptedRefundPolicy"
          valuePropName="checked"
          rules={[
            { required: true, message: "Accept refund policy is reqiured!" },
          ]}
        >
          <Checkbox>
            {t(
              "I hereby certify that I have acknowledged and accepted the refund policy"
            )}
          </Checkbox>
        </Form.Item>
        <Form.Item
          name="acceptedTerms"
          valuePropName="checked"
          rules={[{ required: true, message: "Accept terms is reqiured!" }]}
        >
          <Checkbox>
            {t(
              "I hereby certify that I have acknowledged and accepted the terms and agreement"
            )}
          </Checkbox>
        </Form.Item>

        <Divider />

        <Form.Item>
          <Row gutter={16} justify="space-between" align="middle">
            <Col>
              <Button
                htmlType="button"
                icon={<ArrowLeftOutlined />}
                onClick={onPrev}
              >
                {t("Back")}
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                htmlType="button"
                onClick={() => {
                  form.submit();
                }}
              >
                {t("Next")} <ArrowRightOutlined />
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </div>
  );
};

export default BusinessAgreement;
