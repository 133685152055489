import React from "react";
import { Button, Col, Divider, Form, Radio, Row, Typography } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const { Title, Paragraph } = Typography;

const BusinessType = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  return (
    <>
      <div style={{ textAlign: "center", marginBottom: 48 }}>
        <Paragraph style={{ marginBottom: 24 }}>
          {t("Step")} {t("1")} / {t("8")}
        </Paragraph>

        <Title>{t("Select Partner Type")}</Title>
      </div>

      <Form form={form} name="businessType" autoComplete="off">
        <Form.Item
          name="type"
          valuePropName="value"
          rules={[{ required: true, message: "Missing type!" }]}
        >
          <Radio.Group>
            <Row
              gutter={[
                { xs: 0, sm: 24 },
                { xs: 16, sm: 0 },
              ]}
            >
              <Col xs={24} sm={8}>
                <Radio value="ticket">
                  {t("Ticket Partner")}
                  <Paragraph strong style={{ marginTop: 8 }}>
                    {t("Select if you issue entry tickets for")}
                  </Paragraph>
                  <ul>
                    <li>{t("Archeological Sites")}</li>
                    <li>{t("Monuments & Temples")}</li>
                    <li>{t("Museums")}</li>
                    <li>{t("Cultural Events")}</li>
                    <li>{t("Sporting Events")}</li>
                  </ul>
                </Radio>
              </Col>
              <Col xs={24} sm={8}>
                <Radio value="retail">
                  {t("Retail Partner")}
                  <Paragraph strong style={{ marginTop: 8 }}>
                    {t(
                      "Select if you sell products or services in a physical shop"
                    )}
                  </Paragraph>
                  <ul>
                    <li>{t("Food & beverages")}</li>
                    <li>{t("Consumer goods")}</li>
                    <li>{t("Clothing & accessories")}</li>
                    <li>{t("Souvenirs")}</li>
                    <li>{t("Health & wellness")}</li>
                  </ul>
                </Radio>
              </Col>
              <Col xs={24} sm={8}>
                <Radio value="tour">
                  {t("Tour Partner")}
                  <Paragraph strong style={{ marginTop: 8 }}>
                    {t("Select if you are travel agency companies who sell")}
                  </Paragraph>
                  <ul>
                    <li>{t("Guided tour package")}</li>
                  </ul>
                </Radio>
              </Col>
            </Row>
          </Radio.Group>
        </Form.Item>

        <Divider />

        <Form.Item>
          <Row justify="end">
            <Col>
              <Button
                type="primary"
                htmlType="button"
                onClick={() => {
                  form.submit();
                }}
              >
                {t("Next")} <ArrowRightOutlined />
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </>
  );
};

export default BusinessType;
