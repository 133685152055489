import React from "react";
import { Button, Col, Divider, Form, Input, Row, Typography } from "antd";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const { Title, Paragraph } = Typography;

const BusinessContact = ({ onPrev }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  return (
    <div>
      <div style={{ textAlign: "center", marginBottom: 24 }}>
        <Paragraph style={{ marginBottom: 24 }}>
          {t("Step")} {t("5")} / {t("8")}
        </Paragraph>
        <Title>{t("Enter Business Contact")}</Title>
      </div>

      <div>
        <Form form={form} name="businessContacts" autoComplete="off">
          <Paragraph strong className="mark-required">
            {t("Fields marked with an asterisk ( * ) are required")}
          </Paragraph>
          <Form.List name="businessContacts" initialValue={[{}]}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                  <div key={key}>
                    <Row justify="space-between">
                      <Col xs={22} sm={23}>
                        <Paragraph strong>
                          {index === 0
                            ? t("Primary Contact Person")
                            : t("Secondary Contact Person")}
                          <span className="error">*</span>
                        </Paragraph>
                        <Paragraph>
                          {index === 0
                            ? t("Main responsible and contact person to reach")
                            : t(
                                "Additional person to reach in case the owner is not available"
                              )}
                        </Paragraph>
                        <Form.Item
                          name={[name, "name"]}
                          fieldKey={[fieldKey, "name"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing contact name!",
                            },
                          ]}
                        >
                          <Input placeholder="Contact Name" />
                        </Form.Item>
                        <Form.Item
                          name={[name, "email"]}
                          fieldKey={[fieldKey, "email"]}
                          rules={[
                            {
                              type: "email",
                              message: "The input is not valid email!",
                            },
                            {
                              required: true,
                              message: "Missing email!",
                            },
                          ]}
                        >
                          <Input placeholder="Email" />
                        </Form.Item>
                        <Form.Item
                          name={[name, "phoneNumber"]}
                          fieldKey={[fieldKey, "phoneNumber"]}
                          rules={[
                            {
                              pattern: "^[+]?[0-9]{3}?[0-9]{6,9}$",
                              message: "The input is not valid phone number!",
                            },
                            {
                              required: true,
                              message: "Missing phone number!",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Phone Number"
                            className="w-full"
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Col>
                    </Row>
                    <Divider />
                  </div>
                ))}
                {fields.length < 2 ? (
                  <Row>
                    <Col span={23}>
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          {t("Add Contact")}
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                ) : null}
              </>
            )}
          </Form.List>

          <Divider />

          <Form.Item>
            <Row gutter={16} justify="space-between" align="middle">
              <Col>
                <Button
                  htmlType="button"
                  icon={<ArrowLeftOutlined />}
                  onClick={onPrev}
                >
                  {t("Back")}
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  htmlType="button"
                  onClick={() => {
                    form.submit();
                  }}
                >
                  {t("Next")} <ArrowRightOutlined />
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default BusinessContact;
