import React from "react";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import logo from "assets/logo.svg";

const { Title, Paragraph } = Typography;

export default function Congrats() {
  const { t } = useTranslation();

  return (
    <div style={{ textAlign: "center" }}>
      <Title>
        {t("Congratulations")} <br /> {t("You will soon be an")} <br />
        <div css="display: flex; justify-content:center; align-items:center;">
          <img src={logo} alt="Wonderpass" style={{ width: 512 }} />
          {t("Partner!")}
        </div>
      </Title>
      <Paragraph>
        {t(
          "Allow a few business days for our team to review your application, you will hear from us very soon"
        )}
      </Paragraph>
      <a href="https://wonderpass.asia/">wonderpass.asia</a>
    </div>
  );
}
