export const CATEGORIES = [
  { value: "1", label: "Accomodation", rate: 15, period: 5 },
  { value: "2", label: "Food and Beverage", rate: 20, period: 3 },
  { value: "3", label: "Consumer goods/service", rate: 10, period: 3 },
  { value: "4", label: "Clothing and Accessary", rate: 15, period: 3 },
  { value: "5", label: "Souvenirs", rate: 25, period: 10 },
  { value: "6", label: "Health & wellness", rate: 20, period: 3 },
  { value: "7", label: "Travel", rate: 10, period: 3 },
  { value: "8", label: "Others", rate: 30, period: 15 },
];

export const BANKS = [
  { value: "1", label: "Canadia Bank Plc" },
  { value: "2", label: "Chip Mong Commercial Bank Plc" },
  { value: "3", label: "Phillip Bank Plc" },
  { value: "4", label: "Vattanac Bank Plc" },
  { value: "5", label: "Cambodia Post Bank Plc" },
  { value: "6", label: "Speedpay Plc" },
  { value: "7", label: "ABA Bank" },
  { value: "8", label: "Phnom Penh Commercial Bank" },
  { value: "9", label: "Prasac MFI" },
  { value: "10", label: "AMK Microfinance Plc" },
  { value: "11", label: "WB Finance Co" },
  { value: "12", label: "Sathapana Bank Plc" },
  { value: "13", label: "BIDC Bank" },
  { value: "14", label: "Wing" },
  { value: "15", label: "Chief Commercial Bank" },
  { value: "16", label: "Prince Bank Plc" },
  { value: "17", label: "Foreign Trade Bank of Cambodia" },
  { value: "18", label: "Acleda Bank Plc" },
  { value: "19", label: "LOLC (Cambodia) Plc" },
];
