import React from "react";
import { Button, Col, Divider, Form, Row, Typography, Upload } from "antd";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const { Title, Paragraph } = Typography;

const BusinessImage = ({ onPrev }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const normFile = (e) => {
    // console.log("Upload event:", e);

    if (Array.isArray(e)) return e;

    if (e && e.fileList.length < 1) return e.fileList;

    return e && e.fileList && [e.fileList[e.fileList.length - 1]];
    // return e && e.fileList;
  };

  const beforeUpload = (file) => {
    return false;
  };

  return (
    <>
      <div style={{ textAlign: "center", marginBottom: 24 }}>
        <Paragraph style={{ marginBottom: 24 }}>
          {t("Step")} {t("4")} / {t("8")}
        </Paragraph>
        <Title>{t("Upload Business Image")}</Title>
      </div>

      <Form form={form} name="businessImages">
        <div style={{ marginBottom: 24 }}>
          <Paragraph strong className="mark-required">
            {t("Fields marked with an asterisk ( * ) are required")}
          </Paragraph>

          <Paragraph strong>
            {t("Logo")} <span className="error">*</span>
          </Paragraph>
          <Paragraph>{t("Business logo type image")}</Paragraph>

          <Form.Item
            name="logo"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[{ required: true, message: "Missing business logo!" }]}
          >
            <Upload name="logo" listType="picture" beforeUpload={beforeUpload}>
              <Button icon={<PlusOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
        </div>

        <div style={{ marginBottom: 24 }}>
          <Paragraph strong>{t("Exterior photo")}</Paragraph>
          <Paragraph>
            {t("Add one photograph of the outside of the business")}
          </Paragraph>

          <Form.Item
            name="exteriorPhoto"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              name="exteriorPhoto"
              listType="picture"
              beforeUpload={beforeUpload}
            >
              <Button icon={<PlusOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
        </div>

        <div style={{ marginBottom: 24 }}>
          <Paragraph strong>{t("Interior photo")}</Paragraph>
          <Paragraph>
            {t("Add one photograph of inside the business")}
          </Paragraph>

          <Form.Item
            name="interiorPhoto"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              name="interiorPhoto"
              listType="picture"
              beforeUpload={beforeUpload}
            >
              <Button icon={<PlusOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
        </div>

        <div style={{ marginBottom: 24 }}>
          <Paragraph strong>{t("Additional document")}</Paragraph>
          <Paragraph>{t("Upload any support branding documents")}</Paragraph>

          <Form.Item
            name="additionalDocument"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              name="additionalDocument"
              listType="picture"
              beforeUpload={beforeUpload}
            >
              <Button icon={<PlusOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
        </div>

        <Divider />

        <Form.Item>
          <Row gutter={16} justify="space-between" align="middle">
            <Col>
              <Button
                htmlType="button"
                icon={<ArrowLeftOutlined />}
                onClick={onPrev}
              >
                {t("Back")}
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                htmlType="button"
                onClick={() => {
                  form.submit();
                }}
              >
                {t("Next")} <ArrowRightOutlined />
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </>
  );
};

export default BusinessImage;
