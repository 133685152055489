import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import App from "./App";
import "./i18n";

const Terms = React.lazy(() => import("./components/Terms"));
const Spinner = () => <p>Loading...</p>;

ReactDOM.render(
  <BrowserRouter>
    <Suspense fallback={<Spinner />}>
      <Switch>
        <Route path="/terms-and-conditions">
          <Terms />
        </Route>
        <Route path="/">
          <App />
        </Route>
      </Switch>
    </Suspense>
  </BrowserRouter>,
  document.getElementById("root")
);
